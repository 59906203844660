import PropTypes from "prop-types";
import { useCallback, useContext } from "react";
import get from "lodash/get";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg.jsx";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg.jsx";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { productShape } from "app/utils/propTypes";
import { pushTagOnProductViewButtonClick } from "app/utils/analytics";
import "@brand/Listing/Product/product-with-translucent-deal.scss";
import { OFFER_CONTRACT_TYPES } from "app/constants";
import BadgeList from "app/pages/.shared/ColoredProductBadge/BadgeList";
import ProductDestinationTitleContainer from "app/pages/.shared/ProductDestinationTitle/ProductDestinationTitleContainer";
import ProductTopBar from "@brand/Product/ProductTopBar";
import ProductNameWithCategory from "app/pages/.shared/ProductNameWithCategory/ProductNameWithCategory";
import ProductLinkContainer from "app/pages/.shared/ProductLink/ProductLinkContainer";
import IconRight from "app/pages/.shared/static/icons/IconRight";
import ProductDeal from "app/pages/.shared/Deal/ProductDeal";
import AppGlobalsContext from "app/AppGlobalsContext";

const IMG_SIZES_MOBILE = {
	small: { width: 768, height: 416 },
	medium: { width: 768, height: 416 },
};

const IMG_SIZES_DESKTOP = {
	large: { width: 502, height: 290 },
	xlarge: { width: 502, height: 290 },
};

const CLOUDINARY_OPTIONS = ["c_fill"];

const ProductWithTranslucentDeal = ({
	product = {},
	position,
	showSmartDPOnRootUrl,
	desktopImageSizes = IMG_SIZES_DESKTOP,
	mobileImageSizes = IMG_SIZES_MOBILE,
}) => {
	const { resolution } = useContext(AppGlobalsContext);

	const handleProductClick = useCallback(() => {
		pushTagOnProductViewButtonClick(product, position + 1);
	}, [product.uri, position]);

	const openInNewTab = resolution === RESOLUTION.LARGE || resolution === RESOLUTION.XLARGE;

	const mainPhoto = product.photos && product.photos[0];

	return (
		<div className="product-with-translucent-deal" data-testid="product">
			<div className="product-with-translucent-deal__picture">
				<ProductLinkContainer
					handleProductClick={handleProductClick}
					productUri={product.uri}
					openOnNewTab={openInNewTab}
				>
					{resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL ? (
						<BackgroundProgressiveImg
							sizes={mobileImageSizes}
							cloudinaryOptions={CLOUDINARY_OPTIONS}
							alt={product.country}
							src={mainPhoto && mainPhoto.url}
							thumbnail={mainPhoto && mainPhoto.thumbnail}
						/>
					) : (
						<ProgressiveImg
							className="progressive-img"
							sizes={desktopImageSizes}
							alt={product.country}
							src={mainPhoto && mainPhoto.url}
							thumbnail={mainPhoto && mainPhoto.thumbnail}
						/>
					)}
				</ProductLinkContainer>

				<div className="product-with-translucent-deal__topbar">
					<ProductTopBar product={product} showSmartDPOnRootUrl={showSmartDPOnRootUrl} />
				</div>

				<div className="product-with-translucent-deal__deal">
					<ProductDeal
						isRental={product.contractType === OFFER_CONTRACT_TYPES.RENTAL}
						includeOfferWithFlight={product.includeOfferWithFlight}
						duration={get(product, "fromPriceType.numberOfNights")}
						publicPrice={get(product, "fromPriceType.publicPrice")}
						price={get(product, "fromPriceType.price")}
						hour={get(product, "expiration.hour")}
						day={get(product, "expiration.day")}
						type={get(product, "fromPriceType.type")}
						value={get(product, "fromPriceType.value")}
					/>
				</div>
			</div>
			<div className="product-with-translucent-deal__footer">
				<div className="product-with-translucent-deal__info product-with-translucent-deal__info--left">
					<div className="product-with-translucent-deal__main">
						<ProductDestinationTitleContainer
							country={product.country}
							region={product.region}
							resort={product.resort}
						/>
						<ProductNameWithCategory
							categoryType={product.categoryType}
							category={product.category}
							name={product.name}
						/>
					</div>

					{product.badges && product.badges.length > 0 ? (
						<div className="product-with-translucent-deal__badges">
							<BadgeList badges={product.badges} />
						</div>
					) : (
						false
					)}
				</div>

				<div className="product-with-translucent-deal__info product-with-translucent-deal__info--right">
					<div className="product-with-translucent-deal__action">
						<ProductLinkContainer
							handleProductClick={handleProductClick}
							productUri={product.uri}
							openOnNewTab={openInNewTab}
						>
							<IconRight width={15} height={15} />
						</ProductLinkContainer>
					</div>
				</div>
			</div>
		</div>
	);
};

ProductWithTranslucentDeal.propTypes = {
	position: PropTypes.number,
	product: productShape,
	showSmartDPOnRootUrl: PropTypes.bool,
	desktopImageSizes: PropTypes.object,
	mobileImageSizes: PropTypes.object,
};

export default ProductWithTranslucentDeal;
