import { memo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Timer from "app/pages/.shared/Timer";
import Badge from "app/pages/.shared/Badge";
import { productShape } from "app/utils/propTypes";
import get from "lodash/get";
import { PRODUCT_EXPIRATION_DAY_LIMIT } from "src/@brand/appConstants";

const ProductTopBar = ({ product = {}, showExpiredLabel, showSmartDPOnRootUrl }) => {
	const isFlashProduct = !(get(product, "expiration.day") > PRODUCT_EXPIRATION_DAY_LIMIT);

	const expiredComponent = showExpiredLabel ? (
		<Badge>
			<FormattedMessage id="general.collection.intemporelle" />
		</Badge>
	) : null;

	return (
		<div className="product-top-bar">
			{isFlashProduct ? (
				<Badge>
					<Timer
						hour={get(product, "expiration.hour")}
						day={get(product, "expiration.day")}
						suffix={<FormattedMessage id="timer.left.label" />}
					/>
					{showSmartDPOnRootUrl && (
						<div>
							<FormattedMessage id="sdp.only.for.member.badge.label" />
						</div>
					)}
				</Badge>
			) : (
				expiredComponent
			)}
		</div>
	);
};

ProductTopBar.propTypes = {
	product: productShape,
	showExpiredLabel: PropTypes.bool,
	showSmartDPOnRootUrl: PropTypes.bool,
};

export default memo(ProductTopBar);
