import {
	FETCH_MERCHANDISING,
	FETCH_MERCHANDISING_LIGHT,
	FETCH_MERCHANDISINGS,
	SET_MERCH_PRODUCTS_FILTER_BY,
	UNSET_MERCH_PRODUCTS_FILTER_BY,
	UPDATE_CURRENT_MERCH_CODE,
} from "app/pages/Merchandising/merchandisingActionTypes";
import env from "app/utils/env";
import axios from "axios";
import {
	finishLoadingBar,
	showLoadingBar,
} from "app/pages/.shared/LoadingBar/loadingBarActionCreators";

export const fetchMerchandisings = ({ productsVisibility, shop, partnerCode }) => {
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");
	return {
		type: FETCH_MERCHANDISINGS,
		promise: axios.get(
			`${endpoint}/${productsVisibility}/${shop}/${partnerCode}/merchandisings.json`
		),
	};
};

export const fetchMerchandising = ({ code, shop, productsVisibility, partnerCode }) => {
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_MERCHANDISING,
		promise: axios.get(
			`${endpoint}/${productsVisibility}/${shop}/${partnerCode}/merchandising/${code}.json`
		),
	};
};

export const fetchMerchandisingLight = ({ code, shop, productsVisibility, partnerCode }) => {
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");
	return {
		type: FETCH_MERCHANDISING_LIGHT,
		promise: axios.get(
			`${endpoint}/${productsVisibility}/${shop}/${partnerCode}/merchandising/${code}.json`
		),
	};
};

export const setMerchProductsFilter = filterBy => dispatch => {
	dispatch(showLoadingBar());

	setTimeout(() => {
		dispatch(finishLoadingBar());
	}, 300);

	dispatch({
		type: SET_MERCH_PRODUCTS_FILTER_BY,
		filterBy,
	});
};

export const unsetMerchProductsFilter = name => {
	return {
		type: UNSET_MERCH_PRODUCTS_FILTER_BY,
		name,
	};
};

export const updateCurrentMerchCode = code => {
	return {
		type: UPDATE_CURRENT_MERCH_CODE,
		code,
	};
};
