import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { buildSDPSearchPayload } from "app/utils/smartDPUtils";
import { useHistory } from "react-router-dom";
import { SDP_HERO_TEXT_ALIGN } from "app/constants";
import "./SmartDPImmersiveSearch.scss";
import SmartDPSearchFormContainer from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchFormContainer";
import Paragraphs from "app/pages/.shared/Paragraphs";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";

const IMG_SIZES = {
	medium: { width: 978 },
	small: { width: 768 },
	large: { width: 1847 },
	xlarge: { width: 1847 },
};

const IMG_MOBILE_SIZES = {
	small: {},
	medium: {},
};

const SmartDPImmersiveSearch = ({
	heroTextAlign,
	heroTitleDesktop,
	heroTitleMobile,
	heroSubtitleDesktop,
	heroSubtitleMobile,
	heroImageMobile,
	heroImageDesktop,
	heroImageMobileThumbnail,
	heroImageDesktopThumbnail,
	resetAllSDPProductsFilter = () => {},
	hideSmartDPForm = false,
	heroImageKeepRatio = false,
}) => {
	const history = useHistory();
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const title = isMobile ? heroTitleMobile : heroTitleDesktop;
	const subtitle = isMobile ? heroSubtitleMobile : heroSubtitleDesktop;

	const backgroundImageUrl = isMobile ? heroImageMobile : heroImageDesktop;
	const backgroundImageThumbnail = isMobile
		? heroImageMobileThumbnail
		: heroImageDesktopThumbnail;

	let align = "";
	if (heroTextAlign === SDP_HERO_TEXT_ALIGN.LEFT) {
		align = "flex-start";
	} else if (heroTextAlign === SDP_HERO_TEXT_ALIGN.RIGHT) {
		align = "flex-end";
	}
	const justify = hideSmartDPForm ? "flex-end" : "center";

	const handleFormSubmission = useCallback(data => {
		const queryParams = buildSDPSearchPayload(data);

		resetAllSDPProductsFilter();

		history.push({ pathname: "/sdp/listing", search: queryParams });
	}, []);

	return (
		<div className="smartdp-immersive-search">
			<div className="smartdp-immersive-search__hero">
				{heroImageKeepRatio ? (
					<ProgressiveImg
						sizes={IMG_MOBILE_SIZES}
						thumbnail={backgroundImageThumbnail}
						src={backgroundImageUrl}
					/>
				) : (
					<BackgroundProgressiveImg
						sizes={IMG_SIZES}
						resolution={resolution}
						backgroundPosition={"center"}
						thumbnail={backgroundImageThumbnail}
						src={backgroundImageUrl}
					/>
				)}
				<div
					className="smartdp-immersive-search__hero-content"
					style={{
						justifyContent: justify,
					}}
				>
					<div className="smartdp-immersive-search__marketing">
						<div
							className="smartdp-immersive-search__marketing-container"
							style={{
								alignItems: align,
							}}
						>
							<h1>
								<Paragraphs paragraphs={title} enableHTMLFormatting={true} />
							</h1>
							<div className="smartdp-immersive-search__subtitle">
								<Paragraphs paragraphs={subtitle} enableHTMLFormatting={true} />
							</div>
						</div>
					</div>
					{!isMobile && !hideSmartDPForm && (
						<div className="smartdp-immersive-search__form">
							<SmartDPSearchFormContainer onSuccess={handleFormSubmission} />
						</div>
					)}
				</div>
			</div>
			{isMobile && !hideSmartDPForm && (
				<div className="smartdp-immersive-search__form">
					<SmartDPSearchFormContainer onSuccess={handleFormSubmission} />
				</div>
			)}
		</div>
	);
};

SmartDPImmersiveSearch.propTypes = {
	heroTextAlign: PropTypes.string,
	heroTitleDesktop: PropTypes.array,
	heroTitleMobile: PropTypes.array,
	heroSubtitleDesktop: PropTypes.array,
	heroSubtitleMobile: PropTypes.array,
	heroImageMobile: PropTypes.string,
	heroImageDesktop: PropTypes.string,
	heroImageMobileThumbnail: PropTypes.string,
	heroImageDesktopThumbnail: PropTypes.string,
	resetAllSDPProductsFilter: PropTypes.func,
	hideSmartDPForm: PropTypes.bool,
	heroImageKeepRatio: PropTypes.bool,
};

export default React.memo(SmartDPImmersiveSearch);
