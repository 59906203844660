import { memo, useCallback, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { messagePropType } from "app/utils/propTypes";
import "./TravellersRoomButton.scss";
import classNames from "classnames";
import { scrollToElement } from "app/utils/scroller";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import TravellersRoomForm from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomForm";
import { Drawer } from "app/pages/.shared/Drawer";
import { Popper, usePopper } from "app/pages/.shared/Popper";
import { FormattedMessage } from "react-intl";
import IconLeft from "app/pages/.shared/static/icons/IconLeft";

const TravellersRoomButton = props => {
	const { label, className, icon, initialValues, valueToDisplay, onConfirmation, id } = props;
	const ref = useRef();
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const behavior = usePopper();

	const handleValidation = useCallback(
		values => {
			behavior.handleClose();
			if (ref && ref.current) {
				scrollToElement({ element: ref.current, offset: -300, disableSmoothScroll: false });
			}
			onConfirmation(values.occupancies);
		},
		[ref.current]
	);

	const handleOpen = useCallback(e => {
		behavior.handleOpen(e);
	}, []);

	const handleClose = useCallback(() => {
		behavior.handleClose();
	}, []);

	const inputClassName = classNames(
		"travellers-room-button",
		"travellers-room-button--touched",
		className,
		{
			"travellers-room-button--with-icon": icon,
		}
	);

	const Form = (
		<TravellersRoomForm initialValues={initialValues} handleValidation={handleValidation} />
	);

	return (
		<div className={inputClassName} ref={ref} id={id}>
			<label className="travellers-room-button__label">{label}</label>
			{isMobile ? (
				<>
					<div
						onClick={handleOpen}
						className="travellers-room-button__input"
						data-testid="travellers-room-button"
					>
						{icon && <div className="travellers-room-button__icon">{icon}</div>}
						{valueToDisplay}
					</div>
					<Drawer
						className="travellers-room-button__drawer"
						isOpen={behavior.open}
						onClose={handleClose}
						direction={"rtl"}
					>
						<div className="travellers-room-button__header">
							<div className="travellers-room-button__header-icon">
								<IconLeft width={20} height={20} onClick={handleClose} />
							</div>
							<FormattedMessage id="sdp.search.occupancies.title" />
							<div className="travellers-room-button__header-icon" />
							{/*empty slot*/}
						</div>
						{Form}
					</Drawer>
				</>
			) : (
				<>
					<div
						onClick={handleOpen}
						className="travellers-room-button__input"
						data-testid="travellers-room-button"
					>
						{icon && <div className="travellers-room-button__icon">{icon}</div>}
						{valueToDisplay}
					</div>
					<Popper className="travellers-room-button__popover" behavior={behavior}>
						{Form}
					</Popper>
				</>
			)}
		</div>
	);
};

TravellersRoomButton.propTypes = {
	label: messagePropType,
	className: PropTypes.string,
	icon: PropTypes.element,
	valueToDisplay: PropTypes.element,
	onConfirmation: PropTypes.func,
	initialValues: PropTypes.array,
	id: PropTypes.string,
};

TravellersRoomButton.defaultProps = {
	initialValues: [],
	onConfirmation: () => {},
};

export default memo(TravellersRoomButton);
