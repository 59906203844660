import {
	CLEAR_PRODUCT_PAGE,
	FETCH_FICHE_PRODUIT,
	FETCH_FICHE_PRODUIT_URI,
	FETCH_FIRST_PRODUCTS,
	FETCH_PRODUCTS,
} from "app/actionTypes";
import axios from "axios";
import { getStore } from "app/configureStore";
import env from "app/utils/env";

export const clearProduct = () => {
	return {
		type: CLEAR_PRODUCT_PAGE,
	};
};

export const fetchFirstProducts = ({ spotlight, productsVisibility, shop, partnerCode }) => {
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_FIRST_PRODUCTS,
		spotlight,
		promise: axios.get(
			`${endpoint}/${productsVisibility}/${shop}/${partnerCode}/products.json`
		),
	};
};

export const fetchProducts = ({ productsVisibility, shop, partnerCode }) => {
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");
	return {
		type: FETCH_PRODUCTS,
		promise: axios.get(
			`${endpoint}/${productsVisibility}/${shop}/${partnerCode}/products.json`
		),
	};
};

/**
 * Recupére les infos de la fiche produit
 * @param productUri uri de la fiche produit (ex: pour le produit https://lacollection.airfrance.fr/fr-FR/damac-maison-cour-jardin, l'uri est damac-maison-cour-jardin)
 * @param productId id de la fiche produit. N'est utilisé que par le back office pour leur permettre de retrouver un produit par son id au lieu de son uri
 */
export const fetchFicheProduit = ({ productUri }) => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	const productVisibility = state.productsVisibility; // gestion du mode preview
	const baseUrl = `${endpoint}/${productVisibility}/${shop}`;
	return {
		type: FETCH_FICHE_PRODUIT,
		promise: axios.get(`${baseUrl}/${productUri}.json`),
	};
};

export const fetchFicheProduitUri = ({ productsVisibility, shop, productId }) => {
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");
	const baseUrl = `${endpoint}/${productsVisibility}/${shop}`;
	return {
		type: FETCH_FICHE_PRODUIT_URI,
		promise: axios.get(`${baseUrl}/product-${productId}.json`),
	};
};
